import Navbar from '../../components/Navbar'
import ProjectTitle from '../../components/projectpages/ProjectTitle'
import SandD from '../../components/projectpages/SandD'
import SandPandD from '../../components/projectpages/SandPandD'
import SkillsCustomCard from '../../components/projectpages/SkillsCustomCard'
import CarouselCard from '../../components/projectpages/CC'
import Footer from '../../components/Footer'

import React from 'react'
import { skillsDict } from '../../Data/SkillsCardData'

import explodedView from "../../assets/Projects/A-DRS/a-drs-cad.png"
import aZone_1 from "../../assets/Projects/A-DRS/DRS-GFORCE-ACTIVATION-ZONE.png"
import aZone_2 from "../../assets/Projects/A-DRS/DRS-GFORCE-LOW-ACTIVATION-ZONE.png"
import sps from "../../assets/Projects/SPS-Encoder/assembled-3d.png"
import sps_iso from "../../assets/Projects/SPS-Encoder/Screenshot 2024-02-26 at 12.11.44 PM.png"
import motec_build_transfer from "../../assets/Projects/A-DRS/drs-transfer-motec.png"

function AutoDRS() {

    const imageList = [
        explodedView,
        aZone_1,
        aZone_2,
        sps,
        sps_iso,
        motec_build_transfer
    ]

    const skills = [
        skillsDict['altium'],
        skillsDict['cpp'],
        skillsDict['solidworks'],
    ]
    
  return (
    <div>
        <Navbar/>

        <ProjectTitle 
          title="Drag Reduction System"
          // imgsrc={explodedView}
        />

        <SandD 
          title="Overview"
          p1="In this project, driver lap times improve by 5% through the automatic activation of an FSAE car's drag reduction system (DRS)."
          p2="The inspiration for this project springs from analyzing 2022-23 driving season, where the team detected that over the school year, our drivers only used the DRS button once. Implying the car rarely benefitted from the reduced drag coefficient that the DRS enables. As a result, the teams racing times were far greater than simulated.
          "
          p3="The 2023-24 objective was to enhance driver performance by maximizing the utilization of the DRS. To accomplish this, the plan was to automate the activation of the DRS, thereby eliminating the need for manual activation by the driver while driving."
          p4="My involvement in the project included: (1) Investigating techniques for automating the DRS, (2) Collecting the necessary sensory inputs for reliability and driver safety, and (3) Creating an embedded system capable of communicating with the car's DRS via the CAN Bus"
        />

        <SandPandD 
          title="DRS Activation Research"
          imgsrc={aZone_1}
          img2src={aZone_2}
          p1="In discussing how to approach the automation with the Duke FSAE aerodynamics sub-team, we came to reference the following paper by James Merkel:"
          link={["DEVELOPMENT OF MULTI-ELEMENT ACTIVE AERODYNAMICS FOR THE FORMULA SAE CAR","https://www.academia.edu/33769008/DEVELOPMENT_OF_MULTI_ELEMENT_ACTIVE_AERODYNAMICS_FOR_THE_FORMULA_SAE_CAR"]}          
          // bulletList={inputList}
          p2="The section with the most influence on the project is Chapter 6, titled 'Electronic Design and Tuning.' In particular, Figure 32 stands out, displaying a map that illustrates when the DRS is either active or inactive based on lateral and longitudinal g-force. (This map is reproduced in the top left image of this section.) To understand the graph, when the points on the graph are green, it means the DRS is active. However, during cornering or braking, the points shift into the red zone, indicating that the DRS will become inactive and the maximum amount of downforce becomes available."
          p3="Since the aerodynamic package for the Duke FSAE 2024 car differs from the one described in the paper, I used the activation zone map from the paper as a starting point. Additionally, I introduced a 'low activation' map, depicted in the second figure. This map narrows down the range within which the DRS will activate, providing drivers with greater stability at the end of a cornering events. Moreover, this enables the car to feature multiple 'DRS MODES.' "
          floatImgs={true}
          />

        <SandPandD 
          title="Initial Prototype"
          imgsrc={explodedView}
          // img2src={SW_ROUTED}
          p1="After outlining the initial activation zones, I swiftly put together a prototype to test the concept. This prototype included an ESP32 microcontroller, a CAN transceiver, and two setup buttons. With some programming in C++, this setup formed the foundation of the embedded system, which would:"
          bulletList={[ 
            "Connect to the CAN bus of the vehicle",
            "Extract the vehicle's lateral and longitudinal acceleration",
            "Use the 2D map to determine activations state",
            "Send the activation signals to the servo system using the CAN bus"
         ]}

          p2="The prototype testing results confirmed that the algorithm functioned correctly, activating the lower drag configuration when the vehicle was moving straight and deactivating during corners. However, driver feedback and video playback revealed a slight lag in the activation timing. This indicated that the current system was reactive rather than proactive in its approach."
          reverse={true}
          />

        <SandPandD
          title="DRS Activation Extension"
          imgsrc={sps_iso}
          img2src={sps}
          p1="To enhance our system's predictive capabilities in detecting zones of increased lateral acceleration (cornering or s-curves), I integrated data from the steering wheel encoder project (linked below) with the DRS module. Again, this input was filtered through a 'Base Activation Mode' (with DRS open under ±25º of steering wheel angle) or a 'Low Activation Mode' (with DRS open under ±12º) depending on the mode selection of the driver."
          link={["STEERING POSITION ENCODER", "/projects"]}
          p2="This integration enabled us to anticipate impending shifts in lateral dynamics, optimizing system response and instilling confidence in drivers for adoption"
          floatImg={true}
        />

        <SandPandD
          title="Final System Integration: MoTeC"
          imgsrc={motec_build_transfer}
          p1="In optimizing the vehicle's electrical system for competition, the team made a strategic decision to minimize the use of external components. To achieve this objective, I dedicated effort to integrating the prototype's software seamlessly into the existing ECU firmware."
          p2="This crucial integration facilitated the removal of the DRS control device, reducing unnecessary mass and streamlining our vehicle's design. Moreover, by consolidating functionalities, I effectively decreased the volume of CAN bus messages transmitted, thereby alleviating the load on the CAN bus network."
        />

        <SandD
          title="Results & Reflection"
          p1="The implementation of the automated DRS system yielded significant improvements in our vehicle's performance. Data analysis from test runs showed a 15x increase in DRS utilization compared to the previous manual system. This translated to a 5% reduction in lap times on our standard test track, with the most notable improvements observed on long straights and gradual curves."
          p2="While the current implementation has proven effective, there's room for further optimization. Future iterations could benefit from machine learning algorithms to adapt the activation zones based on individual driver patterns and specific track layouts. Additionally, incorporating predictive modeling using real-time telemetry data could further reduce activation lag."
          p3="Looking ahead, the next evolution of this project could involve developing a multi-active-element aerodynamic setup. This would allow for more nuanced control over the car's aerodynamic profile, potentially offering even greater performance gains across various driving conditions. Such a system would require more complex control algorithms and additional actuators, but could significantly enhance our vehicle's adaptability and overall performance."
        />

        

        <SkillsCustomCard 
          skills={skills}
        />

        <CarouselCard
          imgList={imageList}
          floatImgs={true}
          />

        <Footer/>
    </div>
  )
}

export default AutoDRS
