import "./AboutContentStyles.css"

import React from 'react'

import nate_2_img from "../assets/NathanielAsia-playful.png"

const AboutContent = () => {
  return (
    <section className="about" id="about">

        <div className="center card card-limit">
            <h1>About Me</h1>
            <p> 
                Hello! 👋 I'm Nathaniel (Nate) Asia, an ambitious Electrical Engineering and Computer Science graduate from Duke University. My passion for technology and engineering drives me to explore the exciting intersection of these fields. Through transformative internships and now as a software developer at Rubrik, I've gained invaluable hands-on experience. In my current role, I'm leading projects that apply generative AI to boost developer productivity, continuing to push boundaries and fuel my innovative spirit year-round.
            </p>
            <p>    
                As an Electrical Engineer, I've successfully designed and implemented cutting-edge projects ranging from hardware systems to embedded solutions. My expertise in circuit design, signal processing, and control systems allows me to bring complex ideas to life. Complementing this, my Software Engineering skills enable me to create efficient and elegant solutions in the digital realm. From developing robust algorithms to designing intuitive interfaces, I excel at integrating hardware and software components seamlessly.
            </p>
            <p> 
                My commitment to continuous learning keeps me at the forefront of technological advancements. I constantly stay updated with industry trends and emerging technologies, ensuring I bring fresh perspectives to every project. Beyond technical prowess, I value collaboration and effective communication, recognizing that these soft skills are crucial in navigating the complex landscape of engineering projects.
            </p>
            <p> 
                As you explore my portfolio, you'll discover my diverse skill set, innovative projects, and the enthusiasm I bring to each endeavor. I'm excited to connect with like-minded individuals and organizations who share my vision of pushing technological boundaries. Together, let's shape the future of technology and engineering!
            </p>
        </div>


    </section>
  )
}

export default AboutContent
